import { I13nLink, type YlkData } from "@yahoo-commerce/i13n";
import { type FC } from "react";
import NCPThumbnail from "@/components/common/NCPThumbnail";
import { type TopMerchant } from "@/types/Accio";
import { getMerchantUrl, MERCHANT_LOGO_SIZE } from "@/utils/merchant";

interface CarouselMerchantCardProps {
  dataYlk: YlkData;
  item: TopMerchant;
  thumbnailSize?: string;
}

const size = "120x120";

const CarouselMerchantCard: FC<CarouselMerchantCardProps> = ({
  dataYlk,
  item,
  thumbnailSize = size,
}) => {
  const logoUrl =
    item?.resizedLogoUrls?.[0][MERCHANT_LOGO_SIZE] ?? item?.logoUrls?.[0];

  return (
    <I13nLink
      dataYlk={{
        elm: "link",
        sec: "featured-stores",
        slk: item.name,
        subsec: "store",
        ...dataYlk,
      }}
      href={getMerchantUrl(item)}
      prefetch={true}
      className="group flex w-[7.5rem] flex-col gap-2 pt-4"
    >
      <NCPThumbnail
        alt={item.name}
        className="rounded-full border border-dirty-seagull object-cover transition-all group-hover:scale-[1.034]"
        url={logoUrl}
        size={thumbnailSize}
      />
      <div className="flex flex-col items-center gap-1">
        <h4 className="headline-text-18px md:headline-text-17px text-center group-hover:underline">
          {item.name}
        </h4>
        <p className="text-base font-[450] leading-[1.375rem] tracking-[0.32px] text-battleship md:text-[0.9375rem] md:leading-5 md:tracking-normal">
          {item.dealCount} deals
        </p>
      </div>
    </I13nLink>
  );
};

export default CarouselMerchantCard;
