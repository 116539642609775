import { beaconError } from "@yahoo-commerce/logger";
import { Component, type ErrorInfo } from "react";
import { useRequestContext } from "@/lib/request/client";

const NOOP = () => {};

type Props = {
  // normal UI when no error is thrown
  children: React.ReactNode;
  // optional fallback ui when error
  // is thrown. If error is thrown and
  // no fallback is passed in, we show
  // nothing in its place.
  fallback?: React.ReactNode;
  // unique identifier for this boundary
  // helps when debugging from logs
  id: string;
};

type State = {
  error: Error | null;
  hasError: boolean;
  id: string;
  traceId: string;
};

class ErrorBoundary extends Component<Props & { traceId: string }, State> {
  constructor(props: Props & { traceId: string }) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
      id: props.id,
      traceId: props.traceId,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { error, hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    beaconError(this.state.id, error, errorInfo, this.state.traceId).catch(
      NOOP,
    );
    console.error("Error Boundary caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI
      return this.props.fallback ? this.props.fallback : null;
    }

    // Render children components normally
    return this.props.children;
  }
}

// When perf=1, add traceId to beaconError for tracking
function withTraceId(Component: any) {
  return function WrappedComponent(props: Props) {
    const { traceId, features } = useRequestContext();
    const validTraceId = features.perf ? traceId : "";

    return <Component {...props} traceId={validTraceId} />;
  };
}

export default withTraceId(ErrorBoundary);
