"use client";
import NextImage from "next/image";
import { useEffect, useState, type FC } from "react";
import { type ThumbnailType } from "@/types/NCP";

type Props = {
  alt?: string;
  className?: string;
  thumbnail?: ThumbnailType | null;
  url?: string;
  priority?: boolean;
  size?: string;
};

const fallbackSrc =
  "https://s.yimg.com/cv/apiv2/rocket/ImagePlaceholder16_9.png";

const getRequestedDimensions = (tag: string): [number, number] => {
  const dimensions = tag.split("|")[0];
  const [widthStr, heightStr] = dimensions.split("x");
  const [width, height] = [parseInt(widthStr), parseInt(heightStr)];
  return !Number.isNaN(width) || !Number.isNaN(height)
    ? [width, height]
    : [0, 0];
};

const getImageDimension = ({
  thumbnail,
  size = "original",
}: Pick<Props, "thumbnail" | "size">): {
  width: number;
  height: number;
  url?: string;
} => {
  let requestedWidth = 0,
    requestedHeight = 0;
  let resizedImage = thumbnail?.resolutions?.find((res) => size === res?.tag);

  if (!resizedImage) {
    // Use requested dimensions for placeholder
    [requestedWidth, requestedHeight] = getRequestedDimensions(size);
  }

  const { height, width } = resizedImage || {
    height: requestedHeight,
    width: requestedWidth,
  };

  return {
    height,
    url: resizedImage?.url,
    width,
  };
};

const NCPThumbnail: FC<Props> = ({
  alt = "",
  className,
  thumbnail,
  url,
  priority,
  size = "original",
}) => {
  const {
    width,
    height,
    url: resizedImageUrl,
  } = getImageDimension({ size, thumbnail });

  // https://solutions-image-fallback.vercel.app/
  const [error, setError] = useState(false);
  useEffect(() => {
    setError(false);
  }, [resizedImageUrl]);

  const imageSrc = resizedImageUrl ?? url;

  return (
    <NextImage
      alt={alt}
      className={className}
      height={height}
      onError={() => setError(true)}
      priority={priority}
      src={error ? fallbackSrc : imageSrc || fallbackSrc}
      unoptimized
      width={width}
    />
  );
};

export default NCPThumbnail;
