import { Button } from "@yahoo/uds";
import {
  getDataYlkString,
  I13nAnchor,
  type YlkData,
} from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { type FC, useState } from "react";
import {
  CopyButton,
  buttonClasses,
  shopNowDataYlk,
} from "@/components/common/copyButton/CopyButton";
import NCPThumbnail from "@/components/common/NCPThumbnail";
import { type Deal, DEAL_SET } from "@/types/Accio";

interface ExpiredDateProps {
  expiredDate?: string;
}
interface CarouselCardProps {
  dataYlk: YlkData;
  item: Deal;
}

const EXPIRED_UPPER_DAYS = 5;
const size = "72x72";
export const imageResolution = "150x150";

const displayDealType: Record<DEAL_SET, string> = {
  [DEAL_SET.COUPON]: "CODE",
  [DEAL_SET.SALE]: "SALE",
};

const differenceInDays = (date1: Date, date2: Date): number => {
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
  const diffDays = Math.ceil(
    Math.abs((date2.getTime() - date1.getTime()) / oneDay),
  );
  return diffDays;
};

const displayExpiredDate = (diffDays: number): string => {
  if (diffDays > EXPIRED_UPPER_DAYS) return "";
  return diffDays > 1 ? `${diffDays} days left` : "Expires today";
};

export const shouldShowExpiredDate = (expiredDate?: string) => {
  if (!expiredDate) return false;
  const diffDays = differenceInDays(new Date(expiredDate), new Date());
  return diffDays <= EXPIRED_UPPER_DAYS;
};

const labelClasses =
  "text-[0.8125rem] text-battleship font-[450] leading-4 tracking-[0.26px]";

export const ExpiredDate: FC<ExpiredDateProps> = ({ expiredDate }) => {
  if (!expiredDate) return null;

  const diffDays = differenceInDays(new Date(expiredDate), new Date());
  return (
    <p
      className={classNames(labelClasses, {
        "font-semibold tracking-normal [&]:text-[#d30d2e]": diffDays === 1,
      })}
    >
      {displayExpiredDate(diffDays)}
    </p>
  );
};

const CarouselDealCard: FC<CarouselCardProps> = ({ dataYlk, item }) => {
  const [isActive, setIsActive] = useState(false);

  const itemDataYlk = {
    aid: item?.id,
    pdid: item?.title,
    pid: item?.id,
    seller: item?.retailer?.id,
    sellerN: item?.retailer?.name,
    trk_code: item?.couponCode,
    trk_pct: item?.promotions?.[0]?.value,
  };

  return (
    <div className="flex min-h-[18.125rem] w-[17.5rem] flex-col justify-between rounded-2xl border border-dirty-seagull p-5 sm:min-h-[19.3125rem] md:w-56 xl:w-64">
      <div className="flex flex-col items-center gap-4">
        <NCPThumbnail
          alt={item.title}
          className="rounded-full border border-dirty-seagull object-cover"
          url={item.retailer.logoUrlsBySizes[0][imageResolution]}
          size={size}
        />
        <div className="flex flex-col items-center gap-1.5">
          <p className={labelClasses}>
            <span>{item.retailer.name}</span> ·{" "}
            {displayDealType[item.sourceType as DEAL_SET]}
          </p>
          <h4
            className={classNames(
              "headline-text-18px sm:headline-text-17px text-center",
              isActive
                ? "[&]:line-clamp-1 [&]:md:line-clamp-2"
                : "line-clamp-2 md:line-clamp-3",
            )}
          >
            {item.title}
          </h4>
          <ExpiredDate expiredDate={item.endTime} />
        </div>
      </div>
      {item.couponCode && (
        <CopyButton
          couponCode={item.couponCode}
          merchantName={item.retailer.name}
          dataYlk={{
            ...itemDataYlk,
            ...dataYlk,
          }}
          redirectUrl={item.redirectUrl}
          callback={() => setIsActive(true)}
        />
      )}
      {item.sourceType == DEAL_SET.SALE && (
        <I13nAnchor
          href={item.redirectUrl}
          dataYlk={{
            ...itemDataYlk,
            ...shopNowDataYlk,
            ...dataYlk,
          }}
          target="_blank"
        >
          <Button
            aria-describedby={`Shop now at ${item.retailer.name}`}
            data-ylk={getDataYlkString({
              ...itemDataYlk,
              ...shopNowDataYlk,
              ...dataYlk,
            })}
            variant="tertiary"
            className={classNames(buttonClasses, "h-11 border-thin")}
            tabIndex={-1}
          >
            Shop now
          </Button>
        </I13nAnchor>
      )}
    </div>
  );
};

export default CarouselDealCard;
