import { useEffect, useRef } from "react";

/**
 * Custom hook that returns the previous value of a given state or prop.
 *
 * @template T - The type of the value.
 * @param {T} value - The current value to track.
 * @returns {T | undefined} - The previous value, or undefined if there is no previous value.
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>(undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
