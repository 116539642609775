"use client";
import { Icon } from "@yahoo/uds";
import { ChevronLeft, ChevronRight } from "@yahoo/uds-icons";
import { I13nButton, type YlkData } from "@yahoo-commerce/i13n";
import classNames from "classnames";
import { type FC, type MouseEventHandler } from "react";
import { useCarouselContext } from "./CarouselContext";

const buttonClasses = (isDisabled: boolean) =>
  classNames("group rounded-full border border-[#eceff2] p-2", {
    "bg-[#eceff2] border-[#f0f3f5]": isDisabled,
    "border-[#ebe5ff] bg-hendrix hover:border-[#e1d9ff] hover:bg-[#ebe5ff] active:border-[#cac2ff] active:bg-[#d5cfff] active:text-[#5015b0]":
      !isDisabled,
  });

const iconClasses = (isDisabled: boolean) =>
  classNames({
    "!text-pebble": isDisabled,
    "text-uh-purple": !isDisabled,
  });

interface PaginationProps {
  className?: string;
  countClassName?: string;
  iconSize?: "sm" | "md" | "lg";
  dataYlk?: YlkData;
}

interface NavigationButtonProps {
  arrow?: "next" | "prev";
  className?: string;
  disabled?: boolean;
  handleClick: MouseEventHandler<HTMLButtonElement>;
  dataYlk?: YlkData;
  iconSize?: "sm" | "md" | "lg";
}

export const NavigationButton: FC<NavigationButtonProps> = ({
  arrow = "next",
  className,
  dataYlk,
  disabled = false,
  handleClick,
  iconSize = "md",
}) => {
  return (
    <I13nButton
      className={classNames(buttonClasses(disabled), className)}
      onClick={handleClick}
      dataYlk={{
        elm: "arrow",
        itc: 1,
        slk: arrow,
        ...dataYlk,
      }}
      aria-label={`${arrow} slide`}
      disabled={disabled}
    >
      <Icon
        className={iconClasses(disabled)}
        name={arrow === "next" ? ChevronRight : ChevronLeft}
        size={iconSize}
      />
    </I13nButton>
  );
};

const Pagination: FC<PaginationProps> = ({
  className,
  countClassName,
  iconSize = "md",
  dataYlk = {},
}) => {
  const { current, total, setCurrent } = useCarouselContext();
  const isPrevDisabled = current === 1;
  const isNextDisabled = current === total;

  return (
    <div
      className={classNames("mb-4 flex flex-row items-center gap-4", className)}
    >
      <p
        className={classNames(
          "text-base font-light leading-[1.3125rem] text-primary",
          countClassName,
        )}
      >
        <b className="text-[1.125rem] font-bold leading-[1.4375rem]">
          {current}
        </b>{" "}
        of {total}
      </p>
      <div className="flex flex-row gap-2">
        <NavigationButton
          arrow="prev"
          dataYlk={dataYlk}
          disabled={isPrevDisabled}
          handleClick={() => setCurrent(current - 1)}
          iconSize={iconSize}
        />
        <NavigationButton
          arrow="next"
          dataYlk={dataYlk}
          disabled={isNextDisabled}
          handleClick={() => setCurrent(current + 1)}
          iconSize={iconSize}
        />
      </div>
    </div>
  );
};

export default Pagination;
