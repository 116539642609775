"use client";

import { getComscoreC14, getComscoreFPDM } from "@vzmi/comscore-js";
import comscoreJs from "@vzmi/comscore-js/library-manifest.json";
import { useEsiVars } from "@yahoo-commerce/edge";
import { Comscore } from "@yahoo-commerce/i13n";
import { useDeviceSize } from "@yahoo-commerce/util";
import { type FC, useMemo } from "react";

import { type EdgeSideIncludes } from "@/configs/esi";
import { useGender } from "@/hooks/useGender";
import { getSpaceId } from "@/lib/i13n";
import { useRequestContext } from "@/lib/request/client";

interface Props {}

export const ComscoreConfig: FC<Props> = () => {
  const { site } = useRequestContext();
  const gender = useGender();
  const { age, fpid } = useEsiVars<Pick<EdgeSideIncludes, "age" | "fpid">>({
    age: "",
    fpid: "",
  });
  const deviceSize = useDeviceSize();

  const config = useMemo(() => {
    if (!deviceSize) {
      return null;
    }

    return {
      c14: getComscoreC14(Number(age), gender),
      c5: String(getSpaceId({ deviceSize, site })),
      comscoreJs: comscoreJs.assets.cs,
      fpdm: getComscoreFPDM(Number(age), gender),
      fpid,
    };
  }, [age, deviceSize, fpid, gender, site]);

  if (!config) {
    return null;
  }

  return <Comscore config={config} />;
};
