"use client";
import {
  createContext,
  type FC,
  type ReactNode,
  useContext,
  useState,
} from "react";

interface CarouselContextType {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
}

interface CarouselProviderProps {
  children?: ReactNode;
}

export const CarouselContext = createContext<CarouselContextType>({
  current: 1,
  setCurrent: () => {},
  setTotal: () => {},
  total: 1,
});

export const CarouselProvider: FC<CarouselProviderProps> = ({ children }) => {
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(1);

  return (
    <CarouselContext.Provider
      value={{
        current,
        setCurrent,
        setTotal,
        total,
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
};

export const useCarouselContext = (): CarouselContextType =>
  useContext(CarouselContext);
