"use client";
import { Button, Icon } from "@yahoo/uds";
import { CheckCircle, BoxOnBox, Share } from "@yahoo/uds-icons";
import {
  getDataYlkString,
  I13nAnchor,
  I13nButton,
  type YlkData,
} from "@yahoo-commerce/i13n";
import classNames from "classnames";
import React, { type FC, useEffect, useState } from "react";
import { usePrevious } from "@/hooks/usePrevious";
import "./copyButton.css";

interface CopyCodeProps {
  couponCode: string;
  showShopNow?: boolean;
}

interface CopyButtonProps {
  couponCode: string;
  merchantName: string;
  dataYlk?: YlkData;
  redirectUrl: string;
  reverse?: boolean;
  callback?: () => void;
}

const CopyCode: FC<CopyCodeProps> = ({ couponCode, showShopNow }) => {
  const [showCodeCopied, setShowCodeCopied] = useState(false);

  useEffect(() => {
    if (showCodeCopied) {
      const timer = setTimeout(() => {
        setShowCodeCopied(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showCodeCopied]);

  const onClick = async () => {
    if (!showCodeCopied) {
      setShowCodeCopied(true);
    }

    if (couponCode) {
      try {
        await navigator.clipboard.writeText(couponCode);
      } catch (_) {}
    }
  };

  return (
    <I13nButton
      className="relative flex w-full max-w-full flex-row justify-center"
      onClick={onClick}
      aria-label="Copy code"
      dataYlk={{}}
    >
      <p
        className={classNames(
          "absolute inset-0 flex size-full items-center justify-center gap-1.5 text-[0.8125rem] leading-4",
          "transition-opacity",
          showCodeCopied
            ? "opacity-100 delay-300"
            : "opacity-0 transition-none",
        )}
      >
        <Icon name={CheckCircle} size="sm" color="positive" />
        <span>Code copied</span>
      </p>
      <p
        className={classNames(
          "flex flex-row items-center gap-1 text-[0.8125rem] font-[450] leading-4 tracking-[0.26px] text-battleship",
          { "fade-out": showCodeCopied },
          { "fade-in": showShopNow },
        )}
      >
        Code:
        <b className="truncate font-semibold tracking-normal">{couponCode}</b>
        <Icon name={BoxOnBox} size="sm" color={"battleship" as any} />
      </p>
    </I13nButton>
  );
};

export const shopNowDataYlk = {
  ct: "DEAL",
  elm: "affiliate_link",
  sec: "deals-carousel",
  slk: "Shop now",
  subsec: "deal",
};

export const buttonClasses = "h-11 w-full justify-center";

export const CopyButton: FC<CopyButtonProps> = ({
  couponCode,
  merchantName,
  dataYlk,
  redirectUrl,
  reverse = false,
  callback,
}) => {
  const [showCodeCopied, setShowCodeCopied] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showShopNow, setShowShopNow] = useState(false);
  const prevShowCodeCopied = usePrevious(showCodeCopied);

  useEffect(() => {
    if (showCodeCopied) {
      const timer = setTimeout(() => {
        setShowCodeCopied(false);
        setShowShopNow(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showCodeCopied]);

  useEffect(() => {
    if (prevShowCodeCopied && !showCodeCopied) {
      setIsActive(true);
      callback && callback();
    }
  }, [prevShowCodeCopied, showCodeCopied]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClick = async () => {
    if (!showCodeCopied) {
      setShowCodeCopied(true);
    }

    if (couponCode) {
      try {
        await navigator.clipboard.writeText(couponCode);
      } catch (_) {}
    }
  };

  return (
    <>
      {isActive ? (
        <div
          className={classNames("flex flex-col items-center gap-2", {
            "!flex-col-reverse": reverse,
          })}
        >
          <CopyCode couponCode={couponCode} showShopNow={showShopNow} />

          <I13nAnchor
            aria-label="Shop now"
            className="w-full"
            dataYlk={{
              elm: "shop_now",
              itc: 1,
              slk: "Shop now",
              ...dataYlk,
            }}
            href={redirectUrl}
            target="_blank"
            rel="noopener"
          >
            <Button
              aria-label={`Shop now at ${merchantName}`}
              variant="primary"
              palette="brand"
              tabIndex={-1}
              className={classNames(buttonClasses, "border-thin", {
                "fade-in": showShopNow,
              })}
              data-ylk={getDataYlkString({
                elm: "shop_now",
                itc: 1,
                slk: "Shop now",
                ...dataYlk,
              })}
            >
              <p>
                <span className="flex items-center justify-center gap-2 text-white">
                  Shop now
                  <Icon name={Share} size="sm" color="white" />
                </span>
              </p>
            </Button>
          </I13nAnchor>
        </div>
      ) : (
        <I13nAnchor
          aria-label="Copy code"
          dataYlk={{
            elm: "copy_code",
            itc: 1,
            slk: "Copy code",
            ...dataYlk,
          }}
          onClick={onClick}
        >
          <Button
            variant="tertiary"
            palette="accent"
            className={classNames(buttonClasses, "h-11 border-thin bg-white", {
              "border-0 bg-transparent hover:bg-transparent": showCodeCopied,
            })}
            data-ylk={getDataYlkString({
              elm: "copy_code",
              itc: 1,
              slk: "Copy code",
              ...dataYlk,
            })}
          >
            <p
              className={classNames(
                "absolute inset-0 flex size-full items-center justify-center gap-1.5",
                "opacity-0 transition-opacity duration-1000",
                showCodeCopied ? "fade-in-and-out" : "opacity-0",
              )}
            >
              <Icon
                name={CheckCircle}
                variant="outline"
                size="md"
                color="positive"
              />
              <span
                aria-describedby="Code copied"
                {...(showCodeCopied ? { "aria-hidden": "true" } : {})}
              >
                Code copied
              </span>
            </p>

            <p
              className={classNames({ "fade-out": showCodeCopied })}
              {...(!showCodeCopied ? {} : { "aria-hidden": "true" })}
            >
              Copy code
            </p>
          </Button>
        </I13nAnchor>
      )}
    </>
  );
};
