import { replaceNonAlphanumericUrlPath } from "@yahoo-commerce/util";
import { type TopMerchant } from "@/types/Accio";

const MERCHANT_LOGO_SIZE = "170x170";

const getMerchantUrl = (item: TopMerchant) => {
  if (!item.name) return "";
  const name = replaceNonAlphanumericUrlPath(item.name);
  // TODO: update to use new url when store pages go live
  return `https://shopping.yahoo.com/store/${item.unifiedMerchantId}/${name}/`;
};

export { getMerchantUrl, MERCHANT_LOGO_SIZE };
