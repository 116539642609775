"use client";

import { type YlkData } from "@yahoo-commerce/i13n";
import { debounce, useObservedRef } from "@yahoo-commerce/util";
import classNames from "classnames";
import { type FC, useEffect, useRef } from "react";
import ErrorBoundary from "@/components/common/ErrorBoundary";
import { usePrevious } from "@/hooks/usePrevious";
import { type TopMerchant, type Deal } from "@/types/Accio";
import { CarouselCardType } from "./CarouselCardType";
import { useCarouselContext } from "./CarouselContext";
import CarouselDealCard from "./CarouselDealCard";
import CarouselMerchantCard from "./CarouselMerchantCard";

export interface CarouselProps<T> {
  className?: string;
  items: T[];
  itemsPerSlide?: number;
  onChange?: (current: number) => void;
  type?: CarouselCardType;
  dataYlk?: YlkData;
  slideClassName?: string;
  thumbnailSize?: string;
}

type itemByCardType<T> = T extends CarouselCardType.MERCHANT
  ? TopMerchant
  : Deal;

const chunks = <T extends any>(array: T[], chunkSize: number): T[][] => {
  if (chunkSize <= 0) throw new Error("Chunk size must be greater than 0");

  const result: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

const renderCard = <T extends CarouselCardType>({
  key,
  type,
  item,
  dataYlk,
  thumbnailSize,
}: {
  item: itemByCardType<T>;
  key: string;
  type: T;
  dataYlk: YlkData;
  thumbnailSize?: string;
}) => {
  switch (type) {
    case CarouselCardType.MERCHANT:
      return (
        <ErrorBoundary key={key} id="CarouselMerchantCard">
          <CarouselMerchantCard
            item={item as TopMerchant}
            dataYlk={dataYlk}
            thumbnailSize={thumbnailSize}
          />
        </ErrorBoundary>
      );
    case CarouselCardType.DEAL:
      return (
        <ErrorBoundary key={key} id="CarouselDealCard">
          <CarouselDealCard item={item as Deal} dataYlk={dataYlk} />
        </ErrorBoundary>
      );
    default:
      return null;
  }
};

const Carousel: FC<CarouselProps<Deal | TopMerchant>> = ({
  className,
  items,
  itemsPerSlide = 2,
  type = CarouselCardType.DEAL,
  dataYlk = {},
  slideClassName = "first:pl-4 last:pr-4 sm:flex-row sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8",
  thumbnailSize,
}) => {
  const slides = chunks(items, itemsPerSlide);
  const [scrollRef, isIntersecting] = useObservedRef<HTMLDivElement>({});
  const slideRefs = useRef<(HTMLElement | null)[]>([]);
  const { current, setCurrent, setTotal } = useCarouselContext();
  const prevCurrent = usePrevious(current);

  const handleScroll = debounce(() => {
    if (scrollRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = scrollRef.current;
      let currentIdx =
        Math.round((scrollLeft / scrollWidth) * slides.length) + 1;
      if (scrollLeft + clientWidth >= scrollWidth) {
        currentIdx = slides.length;
      }

      setCurrent(currentIdx);
    }
  }, 100);

  useEffect(() => {
    if (isIntersecting && prevCurrent !== current) {
      slideRefs.current[current]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [prevCurrent, current, isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      setTotal(slides.length);
      setCurrent(1);
    }
  }, [isIntersecting]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const scroller = scrollRef.current;
    if (scroller) {
      scroller.addEventListener("scrollend", handleScroll);
      return () => {
        scroller.removeEventListener("scrollend", handleScroll);
      };
    }
  }, [handleScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={scrollRef}
      className={classNames(
        "no-scrollbar flex snap-x snap-mandatory flex-nowrap gap-5 overflow-x-scroll",
        className,
      )}
    >
      {slides.map((items, slideIdx) => {
        return (
          <div
            ref={(el) => {
              slideRefs.current[slideIdx + 1] = el;
            }}
            key={items[0].id}
            className={classNames(
              "flex snap-start flex-col gap-5 sm:flex-row",
              slideClassName,
            )}
          >
            {items.map((item, itemIdx) => {
              return renderCard({
                dataYlk: {
                  cpos: slideIdx * itemsPerSlide + itemIdx + 1,
                  elmt: item.provider,
                  itc: 0,
                  ...dataYlk,
                },
                item,
                key: item.id,
                thumbnailSize,
                type,
              });
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Carousel;
